import React, { useContext } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import ArrowRight from "../images/careers/arrowRight.svg"
import WhiteArrowRight from "../images/careers/whiteArrowRight.svg"
import Back from "../images/careers/back.svg"
import { LanguagePrefixContext } from "../components/Layout"
import { navigate } from "@gatsbyjs/reach-router"

export const VacanciesPageTemplate = ({ title, t }) => {
  const interpolateTranslation = (translation, key, value) => {
    return translation.replace(`$[${key}]`, value)
  }
  const langPrefix = useContext(LanguagePrefixContext)

  const positions = [
    {
      link: "ruby",
      tag: t.development,
      title: "Ruby " + t.programmer,
      salary: interpolateTranslation(t.salary, "salary", "1 500 EUR"),
      location: t.riga,
    },
    {
      link: "nodejs",
      tag: t.development,
      title: "NODE.JS " + t.programmer,
      salary: interpolateTranslation(t.salary, "salary", "1 500 EUR"),
      location: t.riga,
    },
  ]
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="bg-gray-200 font-light">
        <div className="container mx-auto pt-10 px-10 lg:pt-24 lg:px-24">
          <p className="-mt-16 mb-6 text-xs text-gray-500 hidden lg:block non-italic">
            <Link to={`${langPrefix}careers`}>{t.vacancies}</Link> /{" "}
            <Link to={`${langPrefix}vacancies`}>{t.openPositions}</Link> /{" "}
          </p>
          <p className="text-xs text-gray-500 lg:hidden mb-6">
            <img src={Back} alt="" className="inline pr-2" />
            <button onClick={() => navigate(-1)}>{t.back}</button>
          </p>
          <h2 className="not-italic font-semibold text-3xl text-gray-900 tracking-wide">
            {t.currently} <span className="text-green-600">{t.open}</span>
          </h2>
          <div className="w-full inline-flex mt-5">
            <p className="lg:w-4/5 not-italic font-light text-lg leading-7 text-gray-500 ">
              {t.description1}
              <a
                className="text-green-600 font-black"
                href="mailto:career@makit.lv"
              >
                career@makit.lv
              </a>
              {t.description2}
            </p>
            <Link
              to={`${langPrefix}careers`}
              className="h-12 w-72 not-italic font-black text-lg text-right text-green-400 hidden lg:inline"
            >
              {t.offer}
              {"  "}
              <img src={ArrowRight} className="inline mb-1" alt=""></img>
            </Link>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-6 mb-16 grid-cols-1 gap-6">
            {positions.map((position, i) => (
              <Link
                to={position.link}
                key={i}
                className="relative group text-gray-900 bg-white h-64 xl:h-64 lg:h-72 w-68 hover:text-white hover:bg-green-400 px-8 pt-12 pb-2 tracking-wide"
              >
                <h4 className="w-24 h-6 not-italic font-light text-sm">
                  {position.tag}
                </h4>
                <h2 className="h-fit not-italic font-black text-2xl">
                  {position.title}
                </h2>
                <h4 className="h-6 not-italic font-light text-sm pt-3">
                  {position.salary}
                </h4>
                <h4 className="absolute bottom-4 group-hover:text-white w-8 h-12 not-italic font-light text-sm text-green-600">
                  {position.location}
                </h4>
                <div className="absolute bottom-4 right-6 group-hover:opacity-100 h-10 w-10 bg-black rounded-full float-right opacity-0 mb-0">
                  <img src={WhiteArrowRight} className="p-3" alt=""></img>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

VacanciesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
}

const VacanciesPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <VacanciesPageTemplate
        title={post.frontmatter.title}
        t={post.frontmatter}
      />
    </Layout>
  )
}

VacanciesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VacanciesPage

export const VacanciesPageQuery = graphql`
  query VacanciesPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description1
        description2
        development
        management
        design
        riga
        developer
        designer
        programmer
        assistant
        currently
        open
        offer
        salary
        vacancies
        openPositions
        back
      }
    }
  }
`
